import React from "react";
import lisk from "../assets/lisk.svg"
import circle from "../assets/circle.png"
import fivewest from "../assets/fivewest.png"

const brandsData = [
  {
    imageSrc: lisk,
    lightImageSrc: lisk,
    altText: "graygrids",
    link: "#",
  },
  {
    imageSrc: circle,
    lightImageSrc: circle,
    altText: "lineicons",
    link: "#",
  },
  {
    imageSrc: fivewest,
    lightImageSrc: fivewest,
    altText: "lineicons",
    link: "#",
  },
];

export default function Brand1() {
  return (
    <section className="pb-20 pt-20 dark:bg-dark lg:pb-[90px] lg:pt-[80px]">
      <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-[70px]">
            <span className="mb-2 block text-lg font-semibold text-primary">
              Liquidity Partners
            </span>
            <h2 className="mb-3 text-3xl font-bold leading-[1.2] text-dark dark:text-white sm:text-4xl md:text-[40px]">
              Trusted Supporters of a High-Quality Pool
            </h2>
            <p className="text-base text-body-color dark:text-dark-6">
              Built on the robust Lisk blockchain, this pool is backed by stablecoins issued by industry-leading institutions such as Fivewest and Circle. 
            </p>
            </div>
          </div>
        </div>
            <div className="flex flex-wrap items-center justify-center">
              {brandsData.map((brand, i) => (
                <SingleImage key={i} brand={brand} />
              ))}
            </div>
      </div>
    </section>
  );
}

const SingleImage = ({ brand }) => {
  const { link, imageSrc, lightImageSrc, altText } = brand;
  return (
    <>
      <a
        href={link}
        class="mx-20 flex w-[150px] items-center justify-center py-2 2xl:w-[180px]"
      >
        <img src={imageSrc} alt={altText} class="h-15 w-full dark:hidden" />
        <img
          src={{ lightImageSrc }}
          alt={altText}
          class="hidden h-10 w-full dark:block"
        />
      </a>
    </>
  );
};
