const fetch = require('node-fetch');

const apiEndpoint = "https://api.thegraph.com/subgraphs/name/messari/uniswap-v3-celo";

const query = `
{
  liquidityPoolAmount(id: "0x4213A20bf2a3D62B2c0D6F4888f5d8CD8A862eD7") {
    inputTokenBalances
    tokenPrices
    inputTokens {
      symbol
    }
  }
}
`;

const tokenDecimals = {
  FZAR: 18,
  USDC: 6,
};

function insertDecimal(balanceString, decimals) {
  const length = balanceString.length;
  if (length <= decimals) {
    balanceString = balanceString.padStart(decimals + 1, '0');
  }
  const integerPart = balanceString.slice(0, length - decimals) || '0';
  const decimalPart = balanceString.slice(length - decimals, length).padEnd(2, '0').slice(0, 2);
  return `${integerPart}.${decimalPart}`;
}

async function fetchLiquidityPoolData() {
  try {
    const response = await fetch(apiEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ query: query }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    const liquidityPool = result.data.liquidityPoolAmount;
    const inputTokens = liquidityPool.inputTokens;
    const inputTokenBalances = liquidityPool.inputTokenBalances;
    const USDZARPrice = parseFloat(liquidityPool.tokenPrices[0]);

    const tokenBalances = {};
    inputTokens.forEach((token, index) => {
      const decimals = tokenDecimals[token.symbol] || 18;
      const balanceString = inputTokenBalances[index];
      const formattedBalance = insertDecimal(balanceString, decimals);
      tokenBalances[token.symbol] = parseFloat(formattedBalance);
    });

    console.log('usd zar price', USDZARPrice)

    return { tokenBalances, USDZARPrice };
  } catch (error) {
    console.error("Error fetching data from the GraphQL API", error);
    throw error; // Rethrow the error to handle it in the calling function
  }
}

module.exports = { fetchLiquidityPoolData };
