import React from "react";
import ReactApexChart from "react-apexcharts";

const Chart10 = (
  {
    USDCZAC,
    FZAR,
    fees,
    interest,
    totalZARBal,
    USDC,
    USDCZACRounded
  }
) => {
  const series = [USDCZAC, FZAR, fees, interest];
  const options = {
    chart: {
      fontFamily: "Inter, sans-serif",
      type: "donut",
      width: 380,
    },
    colors: ["#3758F9", "#13C296", "#F2C94C", "#F2994A"],
    labels: ["USDC", "FZAR", "Fees", "FZAR Interest"],
    legend: {
      show: false,
      position: "bottom",
    },

    plotOptions: {
      pie: {
        donut: {
          size: "65%",
          background: "transparent",
        },
      },
    },

    dataLabels: {
      enabled: false,
    },
  };

  return (
    <section className="bg-gray-2 py-20 dark:bg-dark lg:py-[120px]">
      <div className="mx-auto px-4 md:container">
        <div className="mx-auto w-full max-w-[540px] rounded-lg border border-stroke bg-white px-5 pb-5 pt-[30px] dark:border-dark-3 dark:bg-dark-2 sm:px-[30px]">
          <div className="mb-3 justify-between sm:flex">
            <div className="mb-4 sm:mb-0">
              <h5 className="text-xl font-semibold text-dark dark:text-white">
                FZAR / USDC pool composition
              </h5>
              <p className="text-sm text-body-color dark:text-dark-6">
                The total value of the liquidity pool is R{totalZARBal}
              </p>
            </div>
            <div>
              <div className="relative z-20 inline-block rounded-md bg-transparent">
              </div>
            </div>
          </div>

          <div className="mb-2">
            <div id="chartOne" className="chart-10 mx-auto flex justify-center">
              <ReactApexChart
                options={options}
                series={series}
                type="donut"
                width={380}
              />
            </div>
          </div>

          <div className="-mx-8 flex flex-wrap items-center justify-center">
            <AnalyticsItem title="USDC*"  percent={USDCZACRounded} color="#3758F9" />
            <AnalyticsItem title="FZAR" percent={FZAR} color="#13C296" />
            <AnalyticsItem title="Fees" percent={fees} color="#F2C94C" />
            <AnalyticsItem title="Interest" percent={interest} color="#F2994A" />
          </div>
          {/* <p className="flex w-full justify-between text-sm text-dark dark:text-white">*USD units ${USDC}</p> */}
        </div>
      </div>
    </section>
  );
};

export default Chart10;

const AnalyticsItem = ({ title, percent, color }) => {
  return (
    <div className="mb-3 w-full px-8 sm:w-1/2">
      <div className="flex w-full items-center">
        <span
          className={`mr-2 block h-3 w-full max-w-[12px] rounded-full`}
          style={{ backgroundColor: color }}
        ></span>
        <p className="flex w-full justify-between text-sm text-dark dark:text-white">
          <span> {title} </span>
          <span> R{percent} </span>
        </p>
      </div>
    </div>
  );
};
