import './index.css';
import './custom.css';
import Hero6 from './components/hero';
import Service4 from './components/services';
import Table3 from './components/table';
import Accordion2 from './components/faq';
import Stats4 from './components/pool';
import Brand5 from './components/partners'

function App() {
  return (
    <>
      <Hero6
        span={"FZAR / USDC Liquidity Pool"}
        h1a={"Launching Soon:"}
        h1b={"ZAR / USD LP Pool"}
        p={"The FZAR/USDC liquidity pool is launching soon on the Lisk blockchain, allowing you to earn a share of fees when users swap between ZAR (issued by Fivewest) and USDC (issued by Circle). This pool enables seamless currency exchange between the South African Rand (ZAR) and the US Dollar (USDC), with liquidity providers earning returns by acting as market makers."}
      />
      <Brand5/>
      <Service4
        span={"FZAR / USDC liquidity pool benefits"}
        h2={"Why you should provide liquidity to FZAR pool"}
        p={'FZAR is designed to be paired with USD stablecoins to enable remittance to South Africa'}
        s1={"Liquidity Rewards"}
        p1={"Earn additional liquidity rewards (apart from pool fees) from airdrops to the pool"}
        s2={"Fees earned on swaps"}
        p2={"Earn fees from the swaps between FZAR and USDC"}
        s3={"Dollar hedge"}
        p3={"Hedge your ZAR exposure by holding a pool that holds both USD and ZAR "}
        s4={"Make your funds work for you"}
        p4={"Sit back and earn fees and yield while swaps occur between USD and ZAR"}
      />
      <Stats4/>
      <Accordion2
        q1={"What is FZAR backed by?"}
        a1={"FZAR is fully backed by South African Rand (ZAR) deposits, which are held in a segregated deposit account at Tyme Bank. This ensures that every FZAR token is 100% collateralized, providing users with confidence that their holdings are securely underpinned by real-world assets."}
        q2={"Is FZAR regulated?"}
        a2={"Yes, FZAR is issued by Fivewest, a licensed Financial Services Provider (FSP) in South Africa. As a regulated entity, Fivewest adheres to stringent compliance standards, ensuring transparency and security for FZAR users."}
        q3={"Who manages FZAR?"}
        a3={"FZAR is managed by Fivewest, a trusted financial institution responsible for ensuring the stability and functionality of the stablecoin. Fivewest oversees the issuance, backing, and ongoing operations of FZAR to maintain its integrity and value."}
        q4={"What blockchain does FZAR run on?"}
        a4={"FZAR operates on the Lisk blockchain, leveraging its secure and efficient infrastructure for fast, reliable transactions. By running on Lisk, FZAR benefits from a decentralized, scalable platform that supports seamless interactions across a global network."}
        q5={"How is the FZAR / USDC yield generated?"}
        a5={"The yield for FZAR / USDC liquidity providers is generated through two key sources: interest earned from ZAR deposits in a savings account, and fees accrued from traders who swap between USDC and FZAR in the liquidity pool. This creates a sustainable return mechanism for market participants."}
      />
    </>
  );
}

export default App;
