import React, { useState } from 'react';
import { fetchPriceUpdates } from '../api/pythRESTApi';
import { fetchLiquidityPoolData } from '../api/theGraph';
import Chart10 from '../components/graph';

function Stats4() {
  const [usdcBal, setUSDCbal] = useState(0);
  const [usdcBalZar, setUSDCZarbal] = useState(0);
  const [fzarBal, setFZARbal] = useState(0);
  const [totalZARBal, setTotalBal] = useState(0);
  const [ZARprice, setZARprice] = useState(0);
  const [marketZARprice, setmarketZARprice] = useState(0);
  
  const fundUnits = 128000
  const fees = 2504.23
  const interest = 8553.21
  let overalLFund = totalZARBal + fees + interest
  const fundPrice = overalLFund / fundUnits
  let percReturn = (fundPrice-1)*100*12

  function insertDecimal(balanceString, decimals) {
    const length = balanceString.length;
    if (length <= decimals) {
      balanceString = balanceString.padStart(decimals + 1, '0');
    }
    const integerPart = balanceString.slice(0, length - decimals) || '0';
    const decimalPart = balanceString.slice(length - decimals, length).padEnd(2, '0').slice(0, 2);
    return `${integerPart}.${decimalPart}`;
  }

  async function fetchData() {
    try {
      let priceZARUSD = await fetchPriceUpdates();
      priceZARUSD = parseFloat(insertDecimal(priceZARUSD.toString(), 5));
      let usdcZAR = 153222
      let total = 300000
      setZARprice(18.30)
      setmarketZARprice(priceZARUSD)
      setUSDCbal(100);
      setUSDCZarbal(usdcZAR);
      setFZARbal(156223);
      setTotalBal(total);

      return ;
    } catch (error) {
      console.error('Error calculating total ZAR balance:', error);
      throw error; // Rethrow the error to handle it in the calling function
    }
  }
  fetchData();

  return (
    <section className=" dark:bg-dark lg:py-[120px]">
      <div className="mx-auto px-4 sm:container">
        <div className="-mx-4 flex flex-wrap items-center">
          <div className="w-full px-4 lg:w-7/12">
            <div className="mb-12 max-w-[560px] lg:mb-8">
              <span className="mb-4 inline-block rounded bg-primary px-3 py-1 text-base font-medium text-white">
                FZAR / USDC liquidity pool 
              </span>
              <h2 className="mb-3 text-3xl font-bold leading-[1.2] text-dark dark:text-white md:text-[40px]">
                Earn your share of fees and interest from the remittance market 
              </h2>
              <p className="text-base text-body-color dark:text-dark-6">
                Did you know that over R7bn moves in and out of South Africa every day in the forex market? You could earn a share of the fees earned by brokers by simply becoming a liquidity provider against the ZAR / USD pool.
              </p>
            </div>
          </div>
          <div className="w-full px-4 lg:w-5/12">
            <Chart10
                  USDCZAC={usdcBalZar}
                  USDCZACRounded={usdcBalZar.toFixed(2)}
                  FZAR={fzarBal}
                  fees={fees}
                  interest={interest}
                  totalZARBal={overalLFund.toFixed(2)}
                  USDC={usdcBal}
              />
          </div>
        </div>
      </div>


    <section className="bg-white pb-20 dark:bg-dark lg:pb-[120px]">
      <div className="bg-primary pb-[140px] pt-20 lg:pt-[120px]">
        <div className="mx-auto px-4 sm:container">
          <div className="mx-auto max-w-[570px] text-center">
            <span className="mb-2 block text-lg font-semibold text-white">
              How does the pool work?
            </span>
            <h2 className="mb-3 text-3xl font-bold leading-[1.2] text-white md:text-[40px]">
                Volumes are driven by the price difference between the Uniswap pool and the FX market            
                </h2>
            <p className="text-base text-gray-7">
              Traders are incentivised to trade with the Uniswap FZAR / USDC pool to bring the pool price back to parity with the FX market. Volumes create fees for the LP holders.
            </p>
          </div>
        </div>
      </div>
      <div className="-mt-[100px]">
        <div className="mx-auto px-4 sm:container">
          <div className="mx-auto flex max-w-[970px] flex-wrap items-center justify-center rounded-xl bg-white px-10 py-[65px] shadow-2 dark:bg-dark-2 dark:shadow-box-dark">
            <StatsItem2 count={marketZARprice} title="FX Price" />
            <StatsItem2 count={ZARprice.toFixed(2)} title="FZAR / USDC Pool Price" />
          </div>
        </div>
      </div>
    </section>

      {/* <div className="mx-auto px-4 sm:container">
        <div className="-mx-4 flex flex-wrap items-center"> */}
          {/* <div className="w-full px-4 lg:w-12/12">
            <div className="mb-12 max-w-[560px] lg:mb-8">
              <h2 className="mb-3 mt-5 text-3xl font-bold leading-[1.2] text-dark dark:text-white md:text-[40px]">
                FZAR / USDC Statistics
              </h2>
              <p className="text-base text-body-color dark:text-dark-6">
                Own a token of the FZAR / USDC pool and get a share of the interst and fees earned through trading.
              </p>
            </div>
          </div> */}
          {/* <div className="w-full px-4 lg:w-12/12"> */}
            {/* <div className="-mx-3 flex flex-wrap md:-mx-4">
              <StatsItem user={`R`+overalLFund.toFixed(2)} title="Total ZAR Liquidity" />
              <StatsItem user={fundUnits} title="Liquidity Tokens" />
              <StatsItem user={`R`+fundPrice.toFixed(2)} title="Price Per Token" />
              <StatsItem user={percReturn.toFixed(2)+`%`} title="Implied return since inception" />
            </div> */}
          {/* </div> */}
        {/* </div>
      </div> */}
    </section>

  );
}

export default Stats4;

const StatsItem = ({ user, title }) => {
  return (
    <div className="w-full px-3 xs:w-1/2 md:px-4">
      <div className="group mb-6 rounded-[5px] bg-white px-4 py-6 text-center shadow-three hover:bg-primary dark:bg-dark-2 md:mb-8">
        <h4 className="mb-1 text-2xl font-bold leading-tight text-dark group-hover:text-white dark:text-white sm:text-[28px]">
          {user}
        </h4>
        <p className="text-base text-body-color group-hover:text-white dark:text-dark-6">
          {title}
        </p>
      </div>
    </div>
  );
};

const StatsItem2 = ({ count, title }) => {
  return (
    <div className="w-1/2 md:w-1/4">
      <div className="mb-10 text-center md:mb-0">
        <p className="mb-1 text-body-color dark:text-dark-6">{title}</p>
        <span className="block text-4xl font-bold leading-[1.2] text-dark dark:text-white sm:text-[40px]">
          {count}
        </span>
      </div>
    </div>
  );
};