const HERMES_API_URL = 'https://hermes.pyth.network/v2/updates/price/latest';
// https://pyth.network/developers/price-feed-ids#pyth-evm-stable


const priceFeedIds = [
    "0x389d889017db82bf42141f23b61b8de938a4e2d156e36312175bebf797f493f1"
];

async function fetchPriceUpdates() {
    try {
        const url = new URL(HERMES_API_URL);
        priceFeedIds.forEach(id => url.searchParams.append('ids[]', id));

        // Fetch the data from Hermes API
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const price = data.parsed[0].price.price;

        return price
    } catch (error) {
        console.error('Failed to fetch price updates:', error);
    }
}

module.exports = { fetchPriceUpdates };