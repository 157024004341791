import React, { useState } from "react";
import picture from "../assets/fzar-phone.svg"
import logo from "../assets/logo.svg"

const Hero6 = (
  {
    span,
    h1a,
    h1b,
    p
  }
) => {
  return (
    <>
      <Navbar />

      <div className="relative z-10 overflow-hidden bg-white pb-[110px] pt-[120px] dark:bg-dark md:pt-[150px] lg:pt-[180px]">
        <div className="absolute left-0 top-0 z-[-1] h-full w-full rounded-br-[200px] bg-[#F8F8F8] dark:bg-dark-2 lg:w-1/2"></div>
        <div className="container">
          <div className="-mx-4 flex flex-wrap items-center">
            <div className="w-full px-4 lg:w-5/12">
              <div className="hero-content">
                <span className="mb-4 block text-lg font-medium text-primary">
                  {span}
                </span>
                <h1 className="mb-4 text-4xl font-bold !leading-[1.208] text-dark dark:text-white sm:text-[42px] lg:text-[40px] xl:text-5xl">
                  {h1a} <br />
                  {h1b}
                </h1>
                <p className="mb-16 max-w-[440px] text-base text-body-color dark:text-dark-6">
                  {p}
                  <br/>

                  <br/>
                  FZAR is compatible with some of your favourite exchange wallets!
                </p>
                <ul className="flex flex-wrap items-center">
                  <li>
                    <a
                      href="/#"
                      className="group mr-4 inline-flex h-[70px] items-center justify-center rounded-xl bg-white py-[10px] pl-[10px] pr-5 text-center text-base font-medium text-dark shadow-btn transition duration-200 hover:text-primary hover:shadow-lg dark:bg-dark dark:text-white"
                    >
                      <span className="mr-4 text-dark group-hover:text-primary dark:text-dark-2">
                      <svg width="45" height="45" viewBox="0 0 126.61 126.61" xmlns="http://www.w3.org/2000/svg">
                        <g fill="#f3ba2f">
                            <path d="m38.73 53.2 24.59-24.58 24.6 24.6 14.3-14.31-38.9-38.91-38.9 38.9z"/>
                            <path d="m0 63.31 14.3-14.31 14.31 14.31-14.31 14.3z"/>
                            <path d="m38.73 73.41 24.59 24.59 24.6-24.6 14.31 14.29-38.9 38.91-38.91-38.88z"/>
                            <path d="m98 63.31 14.3-14.31 14.31 14.3-14.31 14.32z"/>
                            <path d="m77.83 63.3-14.51-14.52-10.73 10.73-1.24 1.23-2.54 2.54 14.51 14.5 14.51-14.47z"/>
                        </g>
                    </svg>

                      </span>
                      Binance Wallet
                    </a>
                  </li>
                  <li>
                    <a
                      href="/#"
                      className="group inline-flex h-[70px] items-center justify-center rounded-xl py-[10px] pl-[10px] pr-5 text-center text-base font-medium text-dark transition duration-200 hover:text-primary dark:text-white"
                    >
                      <span className="mr-4">
                      <svg width="45" height="45" viewBox="0 0 1024 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width="1024" height="1024" fill="#0052FF"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M152 512C152 710.823 313.177 872 512 872C710.823 872 872 710.823 872 512C872 313.177 710.823 152 512 152C313.177 152 152 313.177 152 512ZM420 396C406.745 396 396 406.745 396 420V604C396 617.255 406.745 628 420 628H604C617.255 628 628 617.255 628 604V420C628 406.745 617.255 396 604 396H420Z" fill="white"/>
                      </svg>
                      </span>
                      Coinbase Wallet
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="hidden px-4 lg:block lg:w-1/12"></div>
            <div className="w-full px-4 lg:w-6/12">
              <div className="mx-auto text-center">
                <div className="relative z-10 mt-16 inline-block lg:mt-0">
                  <img
                    src={picture}
                    alt="hero"
                    className="mx-auto max-w-full"
                  />
                  <div className="absolute -left-10 -top-10 z-[-1]">
                    <img
                      src="https://cdn.tailgrids.com/1.0/assets/images/hero/hero6-shape-1.svg"
                      alt="shape"
                    />
                  </div>
                  <div className="absolute -left-20 top-1/2 z-[-1]">
                    <svg
                      width="40"
                      height="38"
                      viewBox="0 0 40 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.03852 10.0507C2.56762 6.4938 5.49873 2.67846 9.31451 3.18306L32.4926 6.24813C36.3084 6.75273 38.147 11.1988 35.8021 14.2511L21.5587 32.7914C19.2138 35.8436 14.444 35.2129 12.9731 31.656L4.03852 10.0507Z"
                        stroke="#13C296"
                        strokeWidth="6"
                      />
                    </svg>
                  </div>
                  <div className="absolute -right-16 bottom-10 z-[-1]">
                    <svg
                      width="53"
                      height="53"
                      viewBox="0 0 53 53"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.923995 43.5761C3.73599 46.3881 7.07432 48.6187 10.7484 50.1406C14.4224 51.6624 18.3603 52.4457 22.337 52.4457C26.3138 52.4457 30.2516 51.6624 33.9257 50.1406C37.5997 48.6187 40.9381 46.3881 43.7501 43.5761C46.5621 40.7641 48.7927 37.4258 50.3145 33.7517C51.8363 30.0777 52.6196 26.1399 52.6196 22.1631C52.6196 18.1863 51.8363 14.2485 50.3145 10.5744C48.7927 6.90038 46.5621 3.56205 43.7501 0.750054L35.1655 9.3346C36.8502 11.0193 38.1865 13.0192 39.0982 15.2204C40.01 17.4215 40.4792 19.7806 40.4792 22.1631C40.4792 24.5456 40.01 26.9047 39.0982 29.1058C38.1865 31.3069 36.8502 33.3069 35.1655 34.9916C33.4808 36.6762 31.4809 38.0126 29.2797 38.9243C27.0786 39.836 24.7195 40.3053 22.337 40.3053C19.9546 40.3053 17.5954 39.836 15.3943 38.9243C13.1932 38.0126 11.1932 36.6762 9.50854 34.9916L0.923995 43.5761Z"
                        fill="#F9BA45"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero6;

const Navbar = () => {
  const [open, setOpen] = useState(false);

  return (
    <header
      className={`absolute left-0 top-0 z-20 flex w-full items-center bg-transparent`}
    >
      <div className="container">
        <div className="relative -mx-4 flex items-center justify-between">
          <div className="w-60 max-w-full px-4">
            <a href="/#" className="block w-full py-5">
              <img
                src={logo}
                alt="logo"
                className="w-full dark:hidden"
              />
              <img
                src="https://cdn.tailgrids.com/2.0/image/assets/images/logo/logo-white.svg"
                alt="logo"
                className="w-full hidden dark:block"
              />
            </a>
          </div>
          <div className="flex w-full items-center justify-between px-4">
            <div>
            </div>
            <div className="justify-end pr-1 sm:flex lg:pr-0">
              <a
                href="https://form.typeform.com/to/rWRZgpaQ"
                className="rounded-md bg-dark px-6 py-2 text-base font-medium text-white hover:bg-body-color dark:bg-dark-2"
              >
                Sign Up
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
